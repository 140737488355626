
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import useService from "@hooks/useService";
import ButtonOw from "@components/ButtonOw";
import NotFound from "@components/NotFound";
import Modal from 'react-bootstrap/Modal';
import Loading from '@components/Loading'
import { toast } from "react-toastify";

export default function ProjectsEmployee() {
    const { role } = JSON.parse(localStorage.getItem('user_ow'))
    const projects = useService('projects');
    const [paginationData, setPaginationData] = useState({
        totalRegisters: 0
    });
    const [removeUserId, setRemoveUserId] = useState()
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const tooltip = (name) => {
        return (
            <Tooltip id="tooltip">
                <strong>{name}</strong>
            </Tooltip>
        );
    };

    useEffect(() => {
        fetchProjects();
    }, []);



    async function fetchProjects() {
        try {
            setLoading(true);
            // const query = `select=id|text|createdAt|createdBy|imageURL`
            const result = await projects.list();
            setPaginationData(result.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    function loadingPage() {
        return (
            <div className="d-flex justify-content-center align-itens-center mt-5 mb-5">
                <Loading variant='secondary' />
            </div>
        )
    }

    function NothingFound() {
        return (
            <div>
                <div className="notFound">
                    <h4>Não encontramos nenhum dado até o momento</h4>
                    <br />
                    <img alt="No Data" src="/no-com.jpeg" className="blend-img" width={400} />
                </div>
            </div>
        );
    }

    function ListAndPaginate() {
        return (
            <Row>

                {paginationData.list.map((item, index) => {
                    return (
                        <Col md={4} lg={3} sm={6} className="mb-4">
                            <a href={role === 'AUX' ? `projects/aux/${item.id}` : `projects/${item.id}`} className="text-decoration-none">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <h6>{item.name}</h6>
                                        <div className={`status-${item.status.toLocaleLowerCase()}`}>
                                        </div>
                                    </div>

                                </div>
                            </a>
                        </Col>
                    )
                })}
            </Row>
        );
    }

    return (
        <div className="dashboard mt-5">
            <Row>
                <Col md={6}>
                    <h2>Projetos</h2>
                    <p>Separe os projetos internos para melhor aproveitamentos dos dados</p>
                </Col>
            </Row>

            <Row>
                {/* <Col md={4}>
                    <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                        <Form.Control type="text" placeholder="Pesquisar" name="search" className="input" />
                    </Form.Group>
                </Col>
                <Col md={2}>
                    <ButtonOw type={'button'} variant={'secondary'} >Filtrar</ButtonOw>
                </Col> */}


            </Row >

            <Row className="mt-4">
                {loading ? loadingPage() : paginationData.totalRegisters === 0 ? NothingFound() : ListAndPaginate()}
            </Row>

        </div >
    );
}
