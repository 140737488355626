import BaseService from './Base.Service';

class UserService extends BaseService {
    constructor() {
        super('users');
    }

    async getDashboardEmployee() {
        try {
            const { data } = await this.conn.axios.get(`/users/dashboard-emp`);
            return data;
        } catch (err) {
            return this.sendError('Erros foram encontrados ao recuperar dados', false, err);
        }
    }

    async updateBankData(userId, bankData) {
        try {
            const { data } = await this.conn.axios.put(`/user-bank-data/${userId}`, bankData);
            return this.sendSuccess(data, 'Registro atualizado com sucesso!');
        } catch (err) {
            return this.sendError('Erros foram encontrados ao recuperar dados', false, err);
        }
    }


    async updateUser(userId, userData) {
        try {
            const res = await this.conn.axios.put(`/users/${userId}`, userData);
            return this.sendSuccess(res.response.data, 'Registro atualizado com sucesso!');
        } catch (err) {
            return this.sendError('Erros foram encontrados ao recuperar dados', false, err);
        }
    }

    async getBankNames() {
        try {
            const { data } = await this.conn.axios
                .get(`https://brasilapi.com.br/api/banks/v1`);
            return data;
        } catch (err) {
            console.log(err);
            return false;
        }
    }
    async updateUserRole(userId) {
        try {
            const res = await this.conn.axios.post(`/users/change-user-type/`, { userId: userId});
            
            if(res.response.status === 200){
                return res.response.data
            }else {
                return this.sendError('Tivemos um erro ao promover o colaborador, tente novamente', false, res.response.data);
            }
            
        } catch (err) {
            console.log(err)
            return false
            // return this.sendError('Erros foram encontrados ao recuperar dados', false, err);
        }
    }
}

export default UserService;
