import { Col, Row, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import Card from "@components/Card";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import useService from "@hooks/useService";
import ButtonOw from "@components/ButtonOw";
import Modal from "react-bootstrap/Modal";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Loading from "@components/Loading";
import NotFound from "@components/NotFound";
import PaginationOw from "@components/PaginationOw";
import { maskDocument } from "../../../../helpers"
import DragNDrop from "../../../../components/DragNDrop";
export default function Employees() {
	const employeeService = useService("users");
	const user = JSON.parse(localStorage.getItem('user_ow'))
	const [page, setPage] = useState(1)
	const [paginationData, setPaginationData] = useState({
		totalRegisters: 0
	});
	const [search, setSearch] = useState('')
	const [promoteUserId, setPromoteUserId] = useState()
	const [loading, setLoading] = useState(true)
	const [show, setShow] = useState(false);
	const [open, setOpen] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [showUpload, setShowUpload] = useState(false);

	const handleCloseUpload = () => setShowUpload(false);
	const handleOpen = () => setOpen(!open);
	const handleShowUpload = () => setShowUpload(true);

	useEffect(() => {
		fetchEmployees();
	}, [page]);

	async function fetchEmployees() {
		setLoading(true)
		try {
			const result = await employeeService.list('role=EMP|AUX&page=' + page);
			setPaginationData(result.data)
			setLoading(false)
		} catch (err) {
			setLoading(false)
			toast.error(err)
		}
	}

	async function handleSubmit(e) {
		e.preventDefault()
		setLoading(true)
		try {
			const result = await employeeService.list('role=EMP|AUX&name=' + search + '&page=' + 1);
			setPaginationData(result.data);
			setLoading(false)
		} catch (err) {
			toast.error(err)
			setLoading(false)
		}

	}
	function alertCopy() {
		toast.info('Link copiado!', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		});
	}
	async function handlePromoteUser() {
		setLoading(true)
		try {
			const result = await employeeService.updateUserRole(promoteUserId)
			if (!result.error) {
				toast.success('Colaborador promovido com sucesso!')
				handleOpen()
				fetchEmployees()
				setLoading(false)
			}else{
				handleOpen()
				setLoading(false)
			}
		} catch (err) {
			toast.error(err)
			setLoading(false)
		}
	}

	function NothingFound() {
		return (
			<div>
				<div className="notFound">
					<h4>Não encontramos nenhum Colaborador cadastrado, deseja cadastrar um?</h4>
					<ButtonOw type={'button'} variant={'secondary'} onClick={() => handleShow()}>
						Novo colaborador
					</ButtonOw>
					<br />
					<img alt="No Data" src="/no-emp.avif" className="blend-img" width={400} />
				</div>

			</div>
		);
	}

	function ListAndPaginate() {
		
		return (
			<Table hover className="rounded-table">
				<thead>
					<tr>
						<th>Nome</th>
						<th>Documento</th>
						<th>Cargo</th>
						<th>Tipo contrato</th>
						<th style={{ width: '100px' }}></th>
					</tr>
				</thead>
				<tbody>
					{paginationData.list.map((emp, index) => {
						return (
							<tr key={index}>
								<td>{emp.name} {emp.role === 'AUX' && <span>Auxiliar</span>}</td>
								<td>{maskDocument(emp.document)}</td>
								<td>{emp.job}</td>
								<td>{emp.type_contract}</td>
								<td style={{ width: '100px' }}>
									<Dropdown >
										<Dropdown.Toggle variant="white" id="mass-actions-dropdown">
											Opções
										</Dropdown.Toggle>

										<Dropdown.Menu>
											<Dropdown.Item onClick={() => window.location.href = '/c/employe/' + emp.id}>
												<svg
													width={16}
													className="me-2"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth="1.5"
													stroke="currentColor"
												>
													<path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
												</svg>

												Ver Detalhes
											</Dropdown.Item>
											{emp.role !== 'AUX' && <Dropdown.Item onClick={() => { setPromoteUserId(emp.id); handleOpen() }}>
												<svg
													width={16}
													className="me-2"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth="1.5"
													stroke="currentColor"
												>
													<path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
												</svg>


												Promover à Auxiliar
											</Dropdown.Item>}
										</Dropdown.Menu>
									</Dropdown>
								</td>

							</tr>
						)
					})}
				</tbody>
			</Table>
		);
	}

	return (
		<div className="dashboard mt-5">
			<Row className="justify-content-between">
				<Col md={6}>
					<h2>Colaboradores</h2>
					<p>Colaboradores cadatrados.</p>
				</Col>

				<Col md={5} className="offset-md-1 ms-md-auto d-flex gap-3 justify-content-end">
					<ButtonOw variant="white" onClick={handleShowUpload} type={"button"}>
						<svg
							width={18}
							fill="none"
							style={{ marginRight: 10 }}
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="currentColor"
							className="w-4 h-4">
							<path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
						</svg>

						Importar
					</ButtonOw>
					<ButtonOw variant="primary" onClick={handleShow} type={"button"}>
						<svg
							width={18}
							style={{ marginRight: 10 }}
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="currentColor"
							className="w-6 h-6">
							<path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
						</svg>
						Colaborador
					</ButtonOw>
				</Col>
				<Modal show={show} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Envie o QRCode</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h6>Envie esse QRCode para que seu colaborador faça o cadastro em nossa plataforma.</h6>
						<div className="qr-code">
							<QRCode
								size={256}
								style={{ height: "auto", maxWidth: "50%", width: "50%", margin: "auto" }}
								value={window.location.origin + "/register/e/" + user.companyId + '/' + btoa(user.name)}
								viewBox={`0 0 256 256`}
							/>
							<br />
							<br />
							<CopyToClipboard text={window.location.origin + "/register/e/" + user.companyId + '/' + btoa(user.name)}
								onCopy={() => alertCopy()}>
								<ButtonOw variant="white" type={"button"} >
									Copiar link para o colaborador{" "}
									<svg
										width={15}
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth="1.5"
										stroke="currentColor"
										className="w-6 h-6">
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
										/>
									</svg>
								</ButtonOw>
							</CopyToClipboard>

						</div>
					</Modal.Body>
				</Modal>

				<Modal show={showUpload} onHide={handleCloseUpload}>
					<Modal.Header closeButton>
						<Modal.Title>Envie sua planilha para importar colaboradores</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<DragNDrop handleClose={handleCloseUpload} />
					</Modal.Body>
				</Modal>
				<Modal show={open} onHide={handleOpen}>
					<Modal.Header closeButton>
						<Modal.Title>Deseja promover esse colaborador a Auxiliar?</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>Ele terá acesso para aprovar ou reprovar horas de outros colaboradores nos projetos que for inserido.</p>
						<div className="actions" style={{display: 'flex', gap: '20px', justifyContent: 'center', marginTop:40}}>

							

							<ButtonOw
							 variant={'danger'}
								onClick={() => {handleOpen(); setPromoteUserId(null)}}
								className="cancel-btn"
							>
								Cancelar
							</ButtonOw>
							<ButtonOw
								onClick={handlePromoteUser}
								className="upload-btn"
								loading={loading}
							>
								Prosseguir
							</ButtonOw>
						</div>
					</Modal.Body>
				</Modal>
			</Row>


			<Form onSubmit={handleSubmit}>
				<Row>
					<Col md={4} className='mr-2'>
						<Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
							<Form.Control required type="text" placeholder="Pesquisar por nome" name="search" className="input" onChange={(e) => setSearch(e.target.value)} />
						</Form.Group>
					</Col>
					<Col md={1}>
						<ButtonOw type={'submit'} variant={'secondary'} >Filtrar</ButtonOw>
					</Col>
				</Row>
			</Form>


			<Row className="mt-4" style={{ color: '#000' }}>
				<Col>
					{loading ?
						<Loading variant='secondary' className='mb-5 mt-5' /> : paginationData.totalRegisters === 0 ? NothingFound() : ListAndPaginate()}
				</Col>
			</Row>
			<Row>
				<Col md={12} className='d-flex justify-content-center align-itens-center'>
					<PaginationOw totalPages={paginationData.totalPages} currentPage={page} onClick={setPage} />
				</Col>
			</Row>
		</div >
	);
}
